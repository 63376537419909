import TextField from "@mui/material/TextField";
import { withStyles } from "@mui/styles";
import { Formik, useFormik } from "formik";
import { navigate } from "gatsby";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import axiosClient from "../../api-services/axiosClient";
import { preLoginApi } from "../../api-services/users";
import { INITIAL_APP_STATE } from "../../commons/enum";
import CustomButton, {
  CustomOutlineButton,
} from "../../components/custom-button";
import DataLoadingContext from "../../context/data-loading-context";
import { useCompanyInfo } from "../../hooks/company.hooks";
import { useIsLoggedIn } from "../../hooks/user.hooks";
import { useConfirmMFASignIn } from "../../hooks/2fa-setting.hooks.ts";
import { primaryColor } from "../../scss/colors.module.scss";
import { checkEmailVerification } from "../../utils/email-verification";
import { cognitoLogin } from "../../utils/user-auth-provider";
import { EMFAType } from "../account/2fa-setting/index.tsx";

const FORM_TYPES = {
  COMMON: "COMMON",
  SOFTWARE_TOKEN_MFA: "SOFTWARE_TOKEN_MFA",
};

const CssTextField = withStyles({
  root: {
    width: "100%",
    display: "flex",
    marginBottom: "2rem",
    "& label.Mui-focused": {
      color: "#000",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: primaryColor,
      },
      "&:hover fieldset": {
        borderColor: primaryColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: primaryColor,
      },
    },
  },
})(TextField);

const LoginForm = () => {
  const [formType, setFormType] = useState(FORM_TYPES.COMMON);
  const [loginUser, setLoginUser] = useState(null);

  const { data: companyInfo } = useCompanyInfo();
  const { setAppState } = useContext(DataLoadingContext);
  const isLoggedIn = useIsLoggedIn();
  useEffect(() => {
    if (!isLoggedIn) {
      setAppState(INITIAL_APP_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);
  const queryClient = useQueryClient();
  const formRef = useRef("");
  const [gettingLoginStatus, setGettingLoginStatus] = useState(false);

  const handleLogin = () => {
    formRef.current.handleSubmit();
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleLogin();
    }
  };
  const [emailError, setEmailError] = useState("");

  // handle 2FA form
  const confirmSignIn = useConfirmMFASignIn();
  const { enqueueSnackbar } = useSnackbar();
  const confirmMFAForm = useFormik({
    initialValues: {
      token: null,
    },
    validate: (values) => {
      const errors = {};
      if (!values.token) {
        errors.token = "Required";
      }
      return errors;
    },
    onSubmit: async (values) => {
      let mfaMethod = "";
      if (formType === FORM_TYPES.SOFTWARE_TOKEN_MFA) {
        mfaMethod = EMFAType.SOFTWARE_TOKEN_MFA;
      }
      setGettingLoginStatus(true);
      await confirmSignIn.mutateAsync(
        {
          user: loginUser,
          token: values.token,
          mfaMethod,
        },
        {
          onSuccess: async (data) => {
            await queryClient.invalidateQueries("isLoggedIn");
            setLoginUser(data);
          },
          onError: (err) => {
            console.error(err);
            enqueueSnackbar("Failed to verify 2FA", {
              variant: "error",
              autoHideDuration: 3000,
            });
          },
          onSettled: () => {
            setGettingLoginStatus(false);
          },
        }
      );
    },
  });

  const handleCommonLogin = useCallback(async () => {
    axiosClient.defaults.headers.common.authentication =
      loginUser?.signInUserSession?.accessToken?.jwtToken;
    queryClient.invalidateQueries("isLoggedIn");
  }, [loginUser, queryClient]);
  const handleMFAEnabledAccount = useCallback(() => {
    if (loginUser?.challengeName === EMFAType.SOFTWARE_TOKEN_MFA) {
      setFormType(FORM_TYPES.SOFTWARE_TOKEN_MFA);
    }
  }, [loginUser]);
  // handle after amplify login
  useEffect(() => {
    if (isLoggedIn) {
      handleCommonLogin();
    }
    handleMFAEnabledAccount();
  }, [loginUser, isLoggedIn]);

  return (
    <>
      {formType === FORM_TYPES.COMMON && (
        <Formik
          innerRef={formRef}
          initialValues={{ email: "", password: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              setEmailError("Required");
            } else if (checkEmailVerification(values.email)) {
              setEmailError("Invalid email address");
            } else {
              setEmailError("");
            }
            if (!values.password) {
              errors.password = "Required";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            const params = {
              email: values.email,
              password: values.password,
              companyId: companyInfo?.id || "",
            };
            setGettingLoginStatus(true);
            const response = preLoginApi(params);
            response
              .then(async () => {
                const cognitoUser = await cognitoLogin(
                  params.email,
                  params.password
                );
                queryClient.invalidateQueries("isLoggedIn");
                setLoginUser(cognitoUser);
              })
              .catch((err) => {
                let errorMessage = "Something was wrong. Please try again";
                if (err?.message) errorMessage = err?.message;
                if (err?.data?.message) errorMessage = err?.data?.message;
                setEmailError(errorMessage);
              })
              .finally(() => {
                setGettingLoginStatus(false);
              });
            setSubmitting(false);
          }}
        >
          {({ values, touched, handleChange, handleSubmit }) => (
            <form className="login-form" onSubmit={handleSubmit}>
              <CssTextField
                label="Email"
                type="email"
                name="email"
                onChange={handleChange}
                value={values.email}
                variant="outlined"
                id="custom-css-outlined-input"
                error={emailError && touched.email}
              />

              <CssTextField
                id="outlined-password-input"
                label="Password"
                type="password"
                name="password"
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                value={values.password}
                autoComplete="current-password"
                variant="outlined"
                helperText={emailError && touched.email && emailError}
                error={!!emailError && touched.email}
              />
              <div
                className="forgot-password"
                onClick={() => navigate("/forgot-password")}
                role="button"
                tabIndex="0"
                onKeyPress={() => {}}
              >
                <span>Forgot Password?</span>
              </div>

              <div className="submit-button-group">
                <CustomButton
                  isLoading={gettingLoginStatus}
                  label="Login"
                  onKeyPress={() => {}}
                  onClick={() => {
                    handleLogin();
                  }}
                />
              </div>
            </form>
          )}
        </Formik>
      )}
      {
        /* TOTP form type */
        formType === FORM_TYPES.SOFTWARE_TOKEN_MFA && (
          <form
            className="two-fa-confirm-form"
            onSubmit={confirmMFAForm.handleSubmit}
          >
            <h1 className="two-fa-confirm-form__headline">
              Two-Factor authentication
            </h1>
            <CssTextField
              label="Enter authentication code"
              type="token"
              name="token"
              onChange={confirmMFAForm.handleChange}
              value={confirmMFAForm.values.email}
              variant="outlined"
              id="custom-css-outlined-input"
              error={
                confirmMFAForm.touched.token &&
                Boolean(confirmMFAForm.errors.token)
              }
              helperText={
                confirmMFAForm.touched.token && confirmMFAForm.errors.token
              }
            />
            <div className="submit-button-group">
              <CustomButton
                isLoading={gettingLoginStatus}
                label="Confirm"
                onKeyPress={() => {}}
                onClick={() => {
                  confirmMFAForm.handleSubmit();
                }}
              />
              <CustomOutlineButton
                label="Back"
                onKeyPress={() => {}}
                onClick={() => {
                  setFormType(FORM_TYPES.COMMON);
                }}
              />
            </div>
          </form>
        )
      }
    </>
  );
};

export default LoginForm;
